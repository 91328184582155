import React from 'react';
import LoadingTemplate from '@app/templates/LoadingTemplate';
import { Loader } from 'semantic-ui-react';

const LoadingPage: React.FC = () => {
  return (
    <LoadingTemplate>
      <Loader active inverted />
    </LoadingTemplate>
  );
};

export default LoadingPage;
