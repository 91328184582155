import React, { Suspense, lazy } from 'react';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { Header } from '@app/organisms';
import { configureLocalization } from '@app/config';
import { MainTemplate } from '@app/templates';
import { LoadingPage } from '@app/pages';
import { ThemeProvider } from '@emotion/react';
import { GlobalStyles, themes } from '@app/styles';
import 'typeface-alegreya-sans-sc';

const CampaignsPage = lazy(() => import('@app/pages/CampaignsPage'));

configureLocalization();

const App: React.FC = () => {
  const user = null;
  const theme = themes.find((theme) => theme?.name === 'Dark') as any;
  return (
    <AmplifyAuthenticator>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Router>
            <MainTemplate>
              <Header />
              <Suspense fallback={<LoadingPage />}>
                <Route path="/campaigns" />
                <CampaignsPage />
                <Route path="/">
                  <Redirect to="/campaigns" />
                </Route>
              </Suspense>
            </MainTemplate>
          </Router>
        </>
      </ThemeProvider>
    </AmplifyAuthenticator>
  );
};

export default App;
