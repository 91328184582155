import styled from '@emotion/styled';

const MainTemplate = styled.div`
  background: ${(props) => props.theme.background};
  height: 100%;
  position: relative;
  label: main-template;
  display: grid;
  grid-template-rows: max-content auto;
  grid-template-columns: auto;
`;

export default MainTemplate;
