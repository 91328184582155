import React, { ChangeEvent, FormEvent, useState } from 'react';
import D20 from '@app/assets/images/d20-logo-white.png';
import Logo from '@app/assets/images/critical-hit-logo-horizontal-white-text-only.png';
import styled from '@emotion/styled';
import createLogger from '@app/createLogger';
import { useMap } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Button, Input, Label } from '@app/atoms';
import { Form } from '@app/organisms';
import { SignInTemplate } from '@app/templates';
import { TaskState } from '@app/types';

const logger = createLogger({ name: 'SignInPage' });

const MutedImage = styled.img`
  mix-blend-mode: soft-light;
  opacity: 0.5;
`;

const SignInPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [taskState, setTaskState] = useState(TaskState.Initial);
  const [fieldErrors, { set: setError, remove: removeError }] = useMap({
    username: '',
    password: '',
  } as Record<string, string>);
  const { t } = useTranslation();

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e?.target?.value);
    removeError('username');
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e?.target?.value);
    removeError('password');
  };

  const validateFields = () => {
    const errors: Record<string, string> = {};
    if (!username) {
      errors['username'] = t('errors:requiredField');
    }
    if (!password) {
      errors['password'] = t('errors:requiredField');
    }
    Object.entries(errors).forEach(([key, value]) => {
      setError(key, value);
    });
    return errors;
  };

  const onSubmit = (e: FormEvent) => {
    try {
      e.preventDefault();
      setTaskState(TaskState.Pending);
      const errors = validateFields();
      const hasNoErrors = Object.keys(errors).length === 0;
      throw new Error('fail');
    } catch (e) {
      setTaskState(TaskState.Error);
      logger.error(e);
    }
  };

  return (
    <SignInTemplate>
      <Form onSubmit={onSubmit}>
        <MutedImage src={D20} alt="" />
        <img src={Logo} className="logo" alt="" />
        <Form.Field>
          <Label>{t('labels:username')}</Label>
          <Input
            icon="user"
            value={username}
            onChange={onChangeUsername}
            error={!!fieldErrors['username']}
          />
          <Form.Feedback>{fieldErrors['username']}</Form.Feedback>
        </Form.Field>
        <Form.Field>
          <Label>{t('labels:password')}</Label>
          <Input
            type="password"
            icon="lock"
            value={password}
            onChange={onChangePassword}
            error={!!fieldErrors['password']}
          />
          <Form.Feedback>{fieldErrors['password']}</Form.Feedback>
        </Form.Field>
        <Form.Field>
          <a href="/reset-password">{t('labels:resetPassword')}</a>
        </Form.Field>
        <Button primary type="submit" loading={taskState === TaskState.Pending}>
          {t('labels:signIn')}
        </Button>
      </Form>
    </SignInTemplate>
  );
};

export default SignInPage;
