import { Input as SemanticInput } from 'semantic-ui-react';
import styled from '@emotion/styled';

const Input = styled(SemanticInput)`
  /* Change Autocomplete styles in Chrome */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: black;
    transition: background-color 1000s ease-in-out 0s;
    -webkit-transition-delay: 9999s !important;
    transition-delay: 9999s !important;
  }

  > input {
    color: ${(props) => props.theme.colors.text};
    background: transparent;
    background-color: transparent;
  }
`;

export default Input;
