import styled from '@emotion/styled';

const SignInTemplate = styled.div`
  background: ${(props) => props.theme.background};
  height: 100%;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: stretch;
  justify-items: center;
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100vw;
  gap: 1rem;
  form {
    display: grid;
    align-items: start;
    justify-content: stretch;
    grid-template-columns: auto;
    grid-template-rows: 30% 10% repeat(2, max-content) 5rem 1fr;
    gap: 1.5rem;
    img {
      max-width: 75%;
      justify-self: center;
      align-self: center;
    }
    .logo {
      align-self: start;
    }
    & > button:last-child {
      align-self: end;
    }
    @media (max-width: ${(props) => props.theme.breaks.sm}px) {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: ${(props) => props.theme.breaks.sm}px) {
    background-size: 150% 100%;
    background-position: top center;
  }
`;

export default SignInTemplate;
