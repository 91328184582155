import React from 'react';
import Tooltip from '@app/atoms/Tooltip';
import styled from '@emotion/styled';

import { animateFill } from 'tippy.js';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

interface Props {}

const Menu = styled.div``;

/**
 * @example
 * <Dropdown>
 *   <Button>Toggle</Button>
 *   <Dropdown.Menu>
 *     <ul>
 *       <li>Item 1</ul>
 *       <li>Item 2</ul>
 *     </ul>
 *   </Dropdown.Menu>
 * </Dropdown>
 */
const Dropdown: React.FC<Props> = ({ children, ...passthroughProps }) => {
  const childArray = React.Children.toArray(children);
  const content = childArray.find((child: any) => child?.type === Menu) || null;
  const toggle =
    childArray.filter((child: any) => child?.type !== Menu) || null;
  return (
    <Tooltip
      {...passthroughProps}
      trigger="click"
      arrow={false}
      animateFill={true}
      delay={[0, 100]}
      duration={[200, 100]}
      placement="bottom"
      plugins={[animateFill]}
    >
      {toggle}
      <Tooltip.Content>{content}</Tooltip.Content>
    </Tooltip>
  );
};

interface ExtraProps {
  Menu: typeof Menu;
}

const ExtendedDropdown = Dropdown as typeof Dropdown & ExtraProps;
ExtendedDropdown.Menu = Menu;

export default ExtendedDropdown;
