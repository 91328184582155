/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:19f8d09a-c0b3-47bf-add8-aec1236f1d29',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_jZaDyWuiu',
  aws_user_pools_web_client_id: '1pl3e6q2coamgpvl227hfm65qs',
  oauth: {
    domain: 'criticalhit-staging-auth-staging.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://staging.criticalhit.io/',
    redirectSignOut: 'https://staging.criticalhit.io/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default awsmobile;
