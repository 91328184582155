import React from 'react';
import TippyTooltip, { TippyProps } from '@tippyjs/react';
import styled from '@emotion/styled';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';

const Content = styled.div``;

type Props = Omit<TippyProps, 'children'>;

/**
 * @example
 * <Tooltip>
 *   <div>toggle</div>
 *   <Tooltip.Content>
 *     Message
 *   </Tooltip.Content>
 * </Tooltip>
 */
const Tooltip: React.FC<Props> = ({ children, ...passthroughProps }) => {
  const childArray = React.Children.toArray(children);
  const panel =
    childArray.find((child: any) => child?.type === Content) || null;
  const toggle =
    childArray.filter((child: any) => child?.type !== Content)[0] || null;
  return (
    <TippyTooltip
      {...passthroughProps}
      animation="scale-subtle"
      arrow
      inertia
      content={panel}
    >
      {toggle as any}
    </TippyTooltip>
  );
};

interface ExtraProps {
  Content: typeof Content;
}

const ExtendedTooltip = Tooltip as typeof Tooltip & ExtraProps;
ExtendedTooltip.Content = Content;

export default ExtendedTooltip;
