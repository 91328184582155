import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import logo from '@app/assets/images/critical-hit-logo-horizontal-white-text-only.png';
import { useTranslation } from 'react-i18next';
import { Avatar, Container, Dropdown } from '@app/atoms';

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content max-content;
  justify-content: center;
  background: ${(props) => props?.theme?.header?.background};
  border-bottom: ${(props) => props?.theme?.edge?.highlight};
  box-sizing: border-box;
`;

const StyledContainer = styled(Container)`
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: max-content auto max-content;
  grid-auto-flow: column;
  justify-items: start;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  gap: 1rem;

  .logo {
    height: 1.75rem;
    margin-right: 3rem;
  }
`;

const CenteredLink = styled(Link)`
  display: grid;
  align-content: center;
  justify-content: center;
`;

const StyledNav = styled.nav`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, max-content);
  gap: 3rem;
`;

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <StyledContainer>
        <CenteredLink to="/">
          <img src={logo} alt="" className="logo" />
        </CenteredLink>
        <StyledNav>
          <CenteredLink to="/browse">{t('labels:browse')}</CenteredLink>
          <CenteredLink to="/campaigns">{t('labels:campaigns')}</CenteredLink>
          <CenteredLink to="/characters">{t('labels:characters')}</CenteredLink>
        </StyledNav>
        <Dropdown>
          <Avatar src={'http://fake.address.ok'} />
          <Dropdown.Menu>
            <ul>
              <li>What</li>
              <li>What</li>
              <li>What</li>
              <li>What</li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

export default Header;
