import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    errors: require('@app/resources/locales/en/errors.json'),
    labels: require('@app/resources/locales/en/labels.json'),
  },
};

export default function configureLocalization(): void {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: 'en',
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}
