import styled from '@emotion/styled';

const LoadingTemplate = styled.div`
  background: ${(props) => props.theme.background};
  height: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
`;

export default LoadingTemplate;
