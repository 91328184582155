const breaks = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const themes = [
  {
    name: 'Dark',
    //background: '#060609',
    //background:  '#1A1C21; background: -webkit-radial-gradient(top, #1A1C21, #080809); background: -moz-radial-gradient(top, #1A1C21, #080809); background: radial-gradient(to bottom, #1A1C21, #080809)',
    //background: '#161B22; background: -webkit-radial-gradient(top, #161B22, #060609); background: -moz-radial-gradient(top, #161B22, #060609); background: radial-gradient(to bottom, #161B22, #060609);',
    background:
      '#0E1827; background: -webkit-radial-gradient(top, #0E1827, #060609); background: -moz-radial-gradient(top, #0E1827, #060609); background: radial-gradient(to bottom, #0E1827, #060609);',
    border: {
      radius: '3px',
    },
    header: {
      background: '#060609',
    },
    edge: {
      highlight: '1px solid rgba(255, 255, 255, 0.085)',
      shadow: '1px solid rgba(0,0,0,0.1)',
    },
    breaks,
    colors: {
      primary: 'blue',
      danger: 'red',
      text: 'white',
    },
  },
  {
    name: 'Light',
    background:
      '#FFFFFF; background: -webkit-radial-gradient(top, #FFFFFF, #FBFBFB); background: -moz-radial-gradient(top, #FFFFFF, #FBFBFB); background: radial-gradient(to bottom, #FFFFFF, #FBFBFB)',
    border: {
      radius: '3px',
    },
    breaks,
    header: {
      background: 'white',
    },
    edge: {
      highlight: '1px solid rgba(255, 255, 255, 0.085',
      shadow: '1px solid rgba(0,0,0,0.1)',
    },
    colors: {
      primary: 'blue',
      danger: 'red',
      text: 'black',
    },
  },
];

export default themes;
