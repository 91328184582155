import {
  createLogger as createBunyanLogger,
  stdSerializers,
} from 'browser-bunyan';
import { ConsoleFormattedStream } from '@browser-bunyan/console-formatted-stream';

const configDefaults = {
  streams: [
    {
      level: (process.env.REACT_APP_LOG_LEVEL as any) || 'warn',
      stream: new ConsoleFormattedStream(),
    },
  ],
  serializers: stdSerializers,
};

export default function createLogger({ name }: { name: string }) {
  const logger = createBunyanLogger({
    name,
    ...configDefaults,
  });
  return logger;
}
