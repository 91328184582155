import React from 'react';
import { Global, css } from '@emotion/react';
import 'typeface-alegreya-sans-sc';

const styles = css`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

  html,
  body,
  #root {
    margin: 0;
    min-height: 100%;
    min-width: 100%;
    padding: 0;
  }
  #root {
    display: grid;
    align-content: stretch;
    justify-content: stretch;
    position: relative;
  }
  .ui.button {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    outline: 0;
    &:active,
    :hover,
    :visited {
      color: inherit;
      text-decoration: none;
    }
  }
  h1 {
    color: white;
    font-family: 'Alegreya Sans SC', Roboto, sans-serif;
    font-size: 2.25rem;
    font-weight: 400;
  }
`;

export default function GlobalStyles() {
  return <Global styles={styles} />;
}
