import React, { ComponentProps, HTMLProps, forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react';

const Container = styled.div`
  display: grid;
  align-content: stretch;
  justify-content: stretch;
`;

interface Props extends ComponentProps<typeof Container> {
  src: string;
}

const RoundImage = styled.img`
  border-radius: 50%;
`;

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ src, ...passthroughProps }, ref) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
      <Container {...passthroughProps} ref={ref}>
        <RoundImage src={src} alt="" onLoad={() => setIsLoaded(true)} />
        {!isLoaded && <Icon name="user" color="grey" circular />}
      </Container>
    );
  }
);

export default Avatar;
