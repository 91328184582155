import styled from '@emotion/styled';
import { Input, Label } from '@app/atoms';

const Form = styled.form`
  margin: 0;
  padding: 0;
`;

const Field = styled.div`
  display: grid;
  grid-auto-flow: row;
  position: relative;
  > label {
    margin-bottom: 0.25rem;
  }
`;

const Feedback = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.danger};
  padding-top: 0.125rem;
`;

interface ExtraProps {
  Feedback: typeof Feedback;
  Field: typeof Field;
  Input: typeof Input;
  Label: typeof Label;
}

const StyledForm = Form as typeof Form & ExtraProps;
StyledForm.Feedback = Feedback;
StyledForm.Field = Field;
StyledForm.Input = Input;
StyledForm.Label = Label;

export default StyledForm;
