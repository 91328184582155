import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from '@app/reportWebVitals';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import 'semantic-ui-css/semantic.min.css';

// App imports start below the CSS imports
import App from '@app/App';

Amplify.configure(awsconfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
