import React from 'react';
import styled from '@emotion/styled';
import background from '@app/assets/images/backgrounds/castle_dragons_banner.jpg';
import { Container } from '@app/atoms';

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 10rem max-content;
  gap: 1rem;
  justify-content: center;
  justify-items: center;
`;

const Background = styled.div`
  background-image: url(${background});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover, cover;
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom: 4px solid rgba(255, 255, 255, 0.075);
  box-sizing: border-box;
  width: 100%;
`;

const StyledContainer = styled.div``;

const PageHeader: React.FC = ({ children, ...passthroughProps }) => {
  return (
    <StyledHeader {...passthroughProps}>
      <Background />
      <Container>{children}</Container>
    </StyledHeader>
  );
};

export default PageHeader;
